<template>
  <div class="overlay-panel flex-container flex-col">
    <div class="panel-header p24 pb0">
      <div class="mini-logo"></div>
      <h2 class="panel-title nomargin bebas">Benvenuto</h2>
    </div>
    <div
      v-if="isLoading"
      class="flex-container flex-end-align spinner-container"
    >
      <Spinner id="spinner" />
    </div>
    <form v-else class="login flex-container flex-col" @submit.prevent="login">
      <div class="pl24 pr24 pb0">
        <p>Inserisci le tue credenziali</p>
        <div class="flex-container mb24">
          <label class="flex-container flex-col">
            <div class="flex-container heading-and-error">
              <h3 class="bebas nomargin">Email</h3>
              <ErrorMessage :validationStatus="vuel.uemail" />
            </div>

            <input
              required
              v-model="uemail"
              type="email"
              placeholder="Email"
              @blur="handleBlur('uemail')"
              class="noborder nopadding pl8"
            />
          </label>
        </div>
        <div class="flex-container">
          <label class="flex-container flex-col">
            <div class="flex-container heading-and-error">
              <h3 class="bebas nomargin">password</h3>
              <ErrorMessage :validationStatus="vuel.password" />
            </div>

            <input
              required
              v-model="password"
              type="password"
              placeholder="Password"
              @blur="handleBlur('password')"
              class="noborder nopadding pl8"
            />
          </label>
        </div>
      </div>
      <p class="pl24 pr24">
        Hai dimenticato la password ?<router-link to="/forgot"
          >RESET</router-link
        >
      </p>
      <div class="flex-container flex-end-align">
        <div class="flex-container flex-end-align">
          <input
            :disabled="vuel.$invalid"
            type="submit"
            value="Login"
            class="w100 noborder nopadding bebas"
          />
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
import {
  DO_LOGIN,
  ME,
  MESSAGE /* , FORGOT_PASSWORD */,
} from "@/store/VuexPaths";
import ErrorMessage from "@/components/formelements/ErrorMessage";
import { useCapabilities } from "@/utils/Capabilities";
import Spinner from "@/components/Spinner.vue";

export default {
  components: {
    ErrorMessage,
    Spinner,
  },
  setup() {
    const isLoading = ref(false);
    const store = useStore();
    const router = useRouter();

    const password = ref(null);
    const uemail = ref(null);

    const rules = {
      uemail: { required, email },
      password: { required, minLength: minLength(8) },
    };
    const vuel = useVuelidate(rules, {
      uemail,
      password,
    });

    const handleBlur = (key) => {
      vuel.value[key].$dirty = true;
    };

    const { isAdmin, isAdvertiser } = useCapabilities();

    const login = async () => {
      vuel.$dirty = true;
      if (!vuel.$error) {
        isLoading.value = true;
        const delay = new Promise((resolve) => setTimeout(resolve, 1500));
        try {
          const loginAction = store.dispatch(DO_LOGIN, {
            email: uemail.value,
            password: password.value,
          });
          await loginAction;
          await store.dispatch(ME);
          await delay; // Mi assicuro che il delay sia sempre rispettato
          if (isAdmin()) {
            router.push("/");
          } else if (isAdvertiser()) {
            router.push("/campaigns");
          }
        } catch (err) {
          await delay; // Mi assicuro che il delay sia sempre rispettato
          await store.dispatch(MESSAGE, {
            title: "error login",
            message: err.message,
          });
          console.log(err.message);
          isLoading.value = false;
        } finally {
          //isLoading.value = false; // Commentato. Lascio spinnare fino al caricamento completo del sito.
        }
      }
    };
    return {
      password,
      uemail,
      vuel,
      handleBlur,
      login,
      isLoading,
    };
  },
};
</script>

<style scoped>
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Assicurati che il contenitore abbia un'altezza, altrimenti non sarà visibile */
}
</style>
