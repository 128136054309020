<template>
    <div class="overlay w100 h100 abs">
        <login-panel></login-panel>
    </div>
</template>
<script>
import LoginPanel from '@/components/auth/LoginPanel'
export default {
    components:{
        LoginPanel
    }
}
</script>